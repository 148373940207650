import React from 'react';
import classNames from 'classnames';
import { Image } from '@wix/image';
import {
  getImageUri,
  LAYOUT_NAME_ONE_COLUMN_MOBILE,
  type MediaPlatformImage,
} from '@wix/communities-blog-client-common';
import { DEFAULT_MOBILE_VIEWPORT_WIDTH } from '../../services/viewport';
import { isSSR as getIsSSR } from '../../store/basic-params/basic-params-selectors';
import { useResponsiveContext } from '../responsive-listener/responsive-context';
import { useSelector } from '../runtime-context';
import { getImageDimensions } from './get-image-dimensions';
import styles from './post-list-item-image.scss';

type Props = {
  image: Omit<MediaPlatformImage, 'width' | 'height'> &
    Required<Pick<MediaPlatformImage, 'width' | 'height'>>;
  url?: string;
  isPublic?: boolean;
  width: number;
  height: number;
  layoutName: string;
};

export const PostListItemImageMobile: React.FC<Props> = ({
  image,
  url,
  isPublic,
  width,
  height,
  layoutName,
}) => {
  const { rootWidth } = useResponsiveContext();
  const isSSR = useSelector(getIsSSR);

  const dimensions = getImageDimensions({
    container: { width, height },
    component: { width: rootWidth },
    layoutName,
    isSSR,
  });

  const uri = getImageUri(image);
  const aspectRatio = (image.height as number) / (image.width as number);
  const targetWidth = dimensions.width || DEFAULT_MOBILE_VIEWPORT_WIDTH;
  const targetHeight =
    dimensions.height || Math.floor(targetWidth * aspectRatio);
  const style = {
    aspectRatio:
      layoutName === LAYOUT_NAME_ONE_COLUMN_MOBILE
        ? ` ${image.width} / ${image.height}`
        : ` ${targetWidth} / ${targetHeight}`,
  };

  return (
    <div className={classNames(styles.container, styles.mobile)} style={style}>
      {uri ? (
        <Image
          alt=""
          className={styles.image}
          uri={uri}
          displayMode="fill"
          width={image.width as number}
          height={image.height as number}
          targetWidth={targetWidth}
          targetHeight={targetHeight}
          data-hook="post-list-image"
          socialAttrs={
            isPublic ? { 'data-pin-url': url } : { 'data-pin-nopin': true }
          }
        />
      ) : undefined}
    </div>
  );
};

export default PostListItemImageMobile;
